import { styled } from '@mui/material/styles';
import { FC, useEffect } from 'react';
import TeaserPlayer from './TeaserPlayer';
import DownloadAppButton from './DownloadAppButton';
import TeaserTitle from './TeaserTitle';
import useScreenSize from '../../hooks/useScreenSize';
import { ScreenSize } from '../../utils/types';
import { useLocation } from 'react-router-dom';

const TeaserComponent: FC = () => {
  const location = useLocation();
  const screenSize = useScreenSize();

  const imgSrcToUse =
    screenSize === ScreenSize.MOBILE
      ? '/img/cropped-record-smaller-screens.png'
      : '/img/cropped-record-bigger-screens.png';

  useEffect(() => {
    if (location.pathname === '/teaser') {
      const style = document.createElement('style');
      style.innerHTML = `
            #onetrust-consent-sdk {
              display: none !important;
            }
          `;
      style.id = 'hide-cmp-banner-style';
      document.head.appendChild(style);

      return () => {
        // Remove the style when leaving the route
        const existingStyle = document.getElementById('hide-cmp-banner-style');
        if (existingStyle) {
          existingStyle.remove();
        }
      };
    }
  }, [location.pathname]);

  return (
    <TeaserContainer>
      <TeaserTitle />
      <DownloadAppButton />
      <TrackImageWrapper>
        <img src={imgSrcToUse} alt="GOAT Radio record cropped" />
      </TrackImageWrapper>
      <TeaserPlayer />
    </TeaserContainer>
  );
};

export default TeaserComponent;

const TeaserContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#C929FF',
  fontFamily: 'BatonTurbo Md Regular',
  padding: 12,
  borderRadius: 12,
  position: 'relative',
  width: '100%',
  height: '100%',
  overflow: 'hidden',

  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: 16,
    gap: 16,
  },
}));

const TrackImageWrapper = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  position: 'absolute',
  bottom: '-5%',

  '& > img': {
    width: '100%',
    height: '100%',
    position: 'relative',
  },

  [theme.breakpoints.up('md')]: {
    maxWidth: 368,
    left: 'unset',
    bottom: 'unset',
    right: 0,
    top: 0,
    borderTopRightRadius: 12,
  },
}));
